import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PressRelease from "../components/PressRelease"
import { BASE_BREADCRUMBS } from "../constants"

const PressPage = ({ data }) => {
  const [expanded, setExpanded] = useState([])
  const toggleExpanded = i => {
    if (expanded.find(e => e === i)) {
      setExpanded(expanded.filter(e => e !== i))
    } else {
      setExpanded([...expanded, i])
    }
  }
  return (
    <Layout
      pageTitle="Press"
      fullWidth
      breadcrumbs={[...BASE_BREADCRUMBS, { url: "/press", name: "Press" }]}
    >
      <Seo title="Press | Typenex Medical" description="Press page" />
      {data &&
        data.allPressReleases.nodes.map((p, i) => {
          return (
            <PressRelease
              key={i}
              toggleExpanded={toggleExpanded}
              isExpanded={expanded.find(e => e === i + 1)}
              ind={i + 1}
              data={p}
            />
          )
        })}
    </Layout>
  )
}

export default PressPage
export const query = graphql`
  query {
    allPressReleases(sort: {order: DESC, fields: date}) {
      nodes {
        contact {
          name
          position
          phone
          email
        }
        content
        headline
        preview
        subtitle
        location
        date
      }
    }
  }
`
